let _scrollTop
class ModalHelper {
  static afterOpen () {
    _scrollTop = document.scrollingElement.scrollTop
    document.body.style.position = 'fixed'
    document.body.style.top = -_scrollTop + 'px'
  }
  
  static beforeClose () {
    document.body.style.position = ''
    document.body.style.top = ''
    document.scrollingElement.scrollTop = _scrollTop
  }
}
  
export default ModalHelper