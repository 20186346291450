import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
export default createStore({
	state: {
		commentnum: {
			data1: '',
			data2: '',
			data3: '',
		},
		releasenum: {
			data1: '',
			data2: '',
			data3: '',
		},
		userInfo: {},
		numData: { data1: '', data2: '' },
		lineInfo: {},
		testnum: 0,
		searchPage: 1,
		seaInfo: {},
		orderInfo: {},
		routerBool: {},
		start_class: [],
		userOrderList: [],
		listData: {
			data1: 0,
			data2: 0,
			data3: 0,
			data4: 0,
			data5: 0,
		},
		seaData: {
			data1: 0,
			data2: 0,
			data3: 0,
		},
		orderData: {
			data1: 0,
			data2: 0,
			data3: 0,
			data4: 0,
			data5: 0,
		},
		thread_id: '', //教学学员管理线索id
		course_id: '', //教学学员管理课程id
		schedule_id: '', //教学学员管理期数id
		loading: false, //加载bool
		questions: '', //问题管理待回答问题数量
		questionAnswers: '', //问题管理待回答问题数量
	},
	getters: {
		GetLineInfo(state) {
			return state.lineInfo;
		},
	},
	mutations: {
		loginSuccess(state, userInfo) {
			state.userInfo = userInfo;
		},
		loginOut(state) {
			state.userInfo = {};
		},
		setUserId(state, lineInfo) {
			state.lineInfo = lineInfo;
		},
		seaSearch(state, seaInfo) {
			state.seaInfo = seaInfo;
		},
		seaReset(state) {
			state.seaInfo = {};
		},
		setRouterBool(state, routerBool) {
			state.routerBool = routerBool;
		},
		setseaData(state, seaData) {
			state.seaData.data1 = seaData.data1;
			state.seaData.data2 = seaData.data2;
			state.seaData.data3 = seaData.data3;
			state.seaData.data4 = seaData.data4;
		},
		setSearchPage(state, page) {
			state.searchPage = page;
		},
		orderSearch(state, orderInfo) {
			state.orderInfo = orderInfo;
		},
		orderReset(state) {
			state.orderInfo = {};
		},
		setOrderData(state, orderData) {
			state.orderData.data1 = orderData.data1;
			state.orderData.data2 = orderData.data2;
			state.orderData.data3 = orderData.data3;
			state.orderData.data4 = orderData.data4;
		},
		setQuestions(state, questions) {
			state.questions = questions;
		},
		setQuestionAnswers(state, questionAnswers) {
			state.questionAnswers = questionAnswers;
		},
	},
	actions: {},
	modules: {},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
			key: 'store',
			render(state) {
				return { ...state };
			},
		}),
	],
});
