<template>
   <div>
      <el-config-provider :locale="locale">
         <router-view v-if="isRouterAlive" />
      </el-config-provider>
   </div>
</template>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { ref, nextTick, provide } from "vue";
export default {
   data () {
      return {};
   },
   components: {
      ElConfigProvider,
   },
   setup () {
      const isRouterAlive = ref(true);
      const reload = () => {
         isRouterAlive.value = false;
         setTimeout(() => {
            isRouterAlive.value = true;
         }, 1000);
      };
      provide("reload", reload);
      return {
         isRouterAlive,
         locale: zhCn,
      };
   },
   created () {
   },
   computed: {
   },
   methods: {},
};
</script>
<style lang="less">
 .el-table--enable-row-hover .el-table__body tr:hover>td{
 background-color: #f0f5ff !important;
 }
div {
   /deep/ input[type='number']::-webkit-inner-spin-button,
   input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   /deep/ .el-input__inner {
      line-height: 1px !important;
   }
   
   /deep/ .el-dialog__wrapper {
      transition-duration: 0.3s;
   }
}

@import url('assets/css/reset.css');

::-webkit-scrollbar {
   width: 5px;
   height: 10px;
}

::-webkit-scrollbar-thumb {
   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
   background: rgba(0, 167, 232, 0.3);
}

::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
   border-radius: 10px;
   background: #ededed;
}

.el-popover {
   min-width: 20px !important;
   text-align: center !important;
   cursor: pointer;
}

</style>
